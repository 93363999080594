// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

//nuevas dependencias 
import { getFirestore } from "firebase/firestore";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAsH0Hodd4sky-cd07_n_zobnkbWsHHJy8",
  authDomain: "fb-crud-react-bfa52.firebaseapp.com",
  projectId: "fb-crud-react-bfa52",
  storageBucket: "fb-crud-react-bfa52.appspot.com",
  messagingSenderId: "989359904715",
  appId: "1:989359904715:web:4907449e0a8c86d625484e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db=getFirestore(app);