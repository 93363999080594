import React, { useState } from 'react'
import { collection, addDoc } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import { db } from '../firebaseConfig/firebase'

const Create = () => {
    const [descripcion, setDescripcion] = useState('')
    const [stock, setStock] = useState(0)
    const navigate = useNavigate()
    const productsCollection = collection(db, "products")

    const store = async (e) => {
        e.preventDefault()
        await addDoc(productsCollection, { descripcion: descripcion, stock: stock })
        navigate('/')
    }
    return (
        <div className='container'>
            <div className='row'>
                <div className="col">
                    <h1>Create Product</h1>
                    <form onSubmit={store}>
                        <div className='mb-3'>
                            <label className='form-label'>Description</label>
                            <input
                                value={descripcion}
                                onChange={(e) => setDescripcion(e.target.value)}
                                type="text"
                                className='form-control'
                            />
                            </div>

                            <div className='mb-3'>
                            <label className='form-label'>Stock</label>
                            <input
                                value={stock}
                                onChange={(e) => setStock(e.target.value)}
                                type="number"
                                className='form-control'
                            />
                            </div>


                        <button type='submit' className='btn btn-primary'>Store</button>

                    </form>
                </div>
            </div>
        </div>
    )
}

export default Create